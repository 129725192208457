import React, { useState, useEffect } from "react";
import QrScanner from "react-qr-scanner";
import Swal from "sweetalert2";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";

const url="https://parkingapi.ccg.hn:4000";

const QRReader = ({ ...props }) => {
  const [scanning, setScanning] = useState(true); // Activo por defecto
  const [result, setResult] = useState(null);
  const [cameraId, setCameraId] = useState(null); // ID de la cámara trasera
  const [tipoFuncionario, setTipoFuncionario]=useState("");
  const [tipoEstacionamiento, setTipoEstacionamiento]=useState("");
  const [nombreCompleto, setNombreCompleto]=useState("");
  const [institucion, setInstitucion]=useState("");
  const [modalidad, setModalidad]=useState("");
  const [responsableDePago, setResponsableDePago]=useState("");
  const [numeroDeEStacionamiento, setNumeroDeEstacionamiento]=useState("");
  const [marca, setMarca]=useState("");
  const [modelo, setModelo]=useState("");
  const [color, setColor]=useState("");
  const [placa, setPlaca]=useState("");
  const [marca2, setMarca2]=useState("");
  const [modelo2, setModelo2]=useState("");
  const [color2, setColor2]=useState("");
  const [placa2, setPlaca2]=useState("");
  const [idRegistro, setIdRegistro]=useState("");
  const [idEstacionamiento, setIdEstacionamiento]=useState("");
  const [eventos, setEventos]=useState([]);
  const [imagen, setImagen] = useState("");
  const [tipoDeVehiculo, setTipoDeVehiculo] = useState("");
    


  const previewStyle = {
    height: "75%",
    width: "100vw",
  };



  // Obtener ID de la cámara trasera
  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        const videoDevices = devices.filter(function (device) {
          return device.kind === "videoinput";
        });

        // Buscar la cámara trasera
        const backCamera = videoDevices.find(function (device) {
          return device.label.toLowerCase().includes("back");
        });

        // Usar la cámara trasera si está disponible, o la primera cámara
        setCameraId(backCamera ? backCamera.deviceId : (videoDevices[0] && videoDevices[0].deviceId));
      })
      .catch(function (err) {
        console.error("Error al enumerar dispositivos:", err);
      });
  }, []);

  const handleScan = async function (data) {
    if (data) {
      setResult(data.text || data); // Captura el resultado
      setScanning(false); // Desactiva el lector automáticamente
      const consultarRegistro=await consultarInformacionPersonal(data.text);
      if(consultarRegistro){
        const consultarEvento=await consultarEventos(consultarRegistro);
        await guardarEvento(consultarRegistro, consultarEvento);
   
      }
    }
  };

  const handleError = function (err) {
    console.error("Error al escanear:", err);
  };


  const consultarInformacionPersonal=async(numeroQR)=>{
    const res = await fetch(`${url}/asignacionesEstacionamiento/numeroTarjeta/${numeroQR}`);
    const data = await res.json();
    if (data.length > 0) {
        if (!data[0].estado) {
            Swal.fire({
              title: `<span style="font-size: 2rem; font-weight: bold;">QR DESACTIVADO</span>`,
              icon: "warning",
              width: 500,
              iconHtml: '<span style="font-size: 6rem; color: orange;">⚠️</span>', // Ícono personalizado más grande
            });
          return ;        
        }
    
        if (data[0].cajonEstacionamiento.estado && props.evento === "Entrada") {   
            Swal.fire({
                title: `<span style="font-size: 2rem; font-weight: bold;">OTRO VEHÍCULO ASIGNADO AL LUGAR DE ESTACIONAMIENTO YA INGRESÓ</span>`,
                icon: "warning",
                width: 500,
                iconHtml: '<span style="font-size: 6rem; color: orange;">⚠️</span>', // Ícono personalizado más grande
              });
            return;
        }
    
        setTipoFuncionario(data[0].tipo_Funcionario);
        setTipoEstacionamiento(data[0].tipo_Estacionamiento);
        setTipoDeVehiculo(data[0].tipo);
        setNombreCompleto(data[0].nombre_Completo);
        setInstitucion(data[0].institucion.nombre_Institucion);
        setModalidad(data[0].modalidad);
        setResponsableDePago(data[0].responsable_Pago);
        setNumeroDeEstacionamiento(
          `${data[0].cajonEstacionamiento.sotano.nombre_Sotano} - ${data[0].cajonEstacionamiento.estacionamiento}`
        );
        setMarca(data[0].marca);
        setModelo(data[0].modelo);
        setColor(data[0].color);
        setPlaca(data[0].placa);
        setMarca2(data[0].marca2);
        setModelo2(data[0].modelo2);
        setColor2(data[0].color2);
        setPlaca2(data[0].placa2);
        setIdEstacionamiento(data[0].cajonEstacionamiento.sotano.estado)
        setIdRegistro(data[0].informacionTarjetaEstacionamiento);
        return data[0].id_Asignacion_cajon;
    }else{
        Swal.fire({
            title: `<span style="font-size: 2rem; font-weight: bold;">QR NO REGISTRADO EN SISTEMA DE ESTACIONAMIENTO CCG</span>`,
            icon: "warning",
            width: 500,
            iconHtml: '<span style="font-size: 6rem; color: orange;">⚠️</span>', // Ícono personalizado más grande
          });
        return;
    }
  }

  const consultarEventos = async (result) => {
    const res = await fetch(`${url}/eventoTarjetaEstacionamiento?numeroTarjeta=${result}`);
    const data = await res.json();
    setEventos(data);
    return data;
  };

  const guardarEvento = async (idAsignacion, consultaE) => {
    if  (consultaE.length > 0 &&
        (consultaE[consultaE.length - 1].tipo_Registro === props.evento ||
         (consultaE[consultaE.length - 1].tipo_Registro.includes("ANTI-PASSBACK") &&
          consultaE[consultaE.length - 1].tipo_Registro.includes(props.evento)))){
         await fetch(
            `${url}/agregarEventoTarjetaEstacionamiento`,
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                id_Asignacion_cajon: idAsignacion,
                ubicacion: props.ubicacion,
                tipoEvento: `ANTI-PASSBACK DE ${props.evento}`,
                id_Usuario: props.idUsuario,
              }),
            }
          );
      Swal.fire({
        title: `<span style="font-size: 2.8rem; font-weight: bold;">ERROR</span>`,
        html: `<p style="font-size: 2.5rem;">¡ANTI-PASSBACK ACTIVADO! EL VEHÍCULO YA REALIZÓ UNA ${props.evento} A LAS ${consultaE[consultaE.length - 1].hora}.</p>`,
        icon: "error",
        width: 500,
        iconHtml: '<span style="font-size: 6rem; color: red;">❌</span>', // Ícono más grande y personalizado
      });
      return
    }

   

    if (consultaE.length === 0 && props.evento === "Salida") {
      Swal.fire({
        title: `<span style="font-size: 2.8rem; font-weight: bold;">ERROR</span>`,
        html: `<p style="font-size: 2.5rem;">¡ANTI-PASSBACK! NO SE PUEDE AGREGAR SALIDA SI NO HAY ENTRADA REGISTRADA</p>`,
        icon: "error",
        width: 500,
        iconHtml: '<span style="font-size: 6rem; color: red;">❌</span>', // Ícono más grande y personalizado
      });
      return;
    }

    const res2 = await fetch(
      `${url}/agregarEventoTarjetaEstacionamiento`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id_Asignacion_cajon: idAsignacion,
          ubicacion: props.ubicacion,
          tipoEvento: props.evento,
          id_Usuario: props.idUsuario,
        }),
      }
    );

    await res2.json();

    if (props.evento === "Salida") {
      await fetch(
        `${url}/cajonesEstacionamiento/${idEstacionamiento}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            estado: false,
          }),
        }
      );
    } 
    
    await fetch(
        `${url}/cajonesEstacionamiento/${idEstacionamiento}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            estado: true,
          }),
        }
      );
  
      Swal.fire({
        title: `<span style="font-size: 2.8rem; font-weight: bold;">REGISTRO DE EVENTOS</span>`,
        html: `<p style="font-size: 2.5rem;">¡ SE HA AGREGADO UN EVENTO DE ${props.evento} EN LA UBICACIÓN ${props.ubicacion}!</p>`,
        icon: "success",
        width: 500,
        iconHtml: '<span style="font-size: 6rem; color: green;">✅</span>', // Ícono más grande y personalizado
      });
      return; 


  };

  const limpiarFormulario = () => {
    setTipoFuncionario("");
    setTipoEstacionamiento("");
    setTipoDeVehiculo("");
    setNombreCompleto("");
    setInstitucion("");
    setNumeroDeEstacionamiento("");
    setMarca("");
    setModelo("");
    setColor("");
    setPlaca("");
    setMarca2("");
    setModelo2("");
    setColor2("");
    setPlaca2("");
    setImagen("");
    setIdRegistro("");
    setEventos([]);
    setResult(null)
  };


  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {scanning ? (
        cameraId ? (
          <QrScanner
            delay={300}
            style={previewStyle}
            onError={handleError}
            onScan={handleScan}
            constraints={{
              video: { deviceId: cameraId, width: { ideal: 1280 }, // Resolución más alta
              height: { ideal: 720 }, }, // Fuerza el uso de la cámara seleccionada

            }}
          />
        ) : (
          <p>Cargando cámara...</p>
        )
      ) : null}

      {result && (
        <div style={{flexDirection: "column",justifyContent: "center",width: "auto",}}>
        <figure>
          <img style={{ height: "150px", width: "150px" }} src={imagen} />
          <figcaption style={{ color: "white", fontSize: "22px" }}>
            {nombreCompleto}
          </figcaption>
          <hr />
        </figure>
        <div
          style={{
            display: "flex",flexDirection: "column",justifyContent: "center",width: "auto",marginTop: "30px",}}>
          <label style={{ color: "white", width: "auto" }}>
            <strong>Modalidad: </strong>
            <span>{modalidad}</span>
          </label>
          <label style={{ color: "white", width: "auto" }}>
            <strong>Responsable De Pago: </strong>
            <span>{responsableDePago}</span>
          </label>
          <label style={{ color: "white", width: "auto" }}>
            <strong>Tipo de Vehículo: </strong>
            {tipoDeVehiculo ? (
              <DirectionsCarIcon fontSize='large' />
            ) : (
              <TwoWheelerIcon fontSize='4rem' />
            )}
          </label>
          <label style={{ color: "white", width: "auto" }}>
            <strong>Institución: </strong> <span>{institucion}</span>
          </label>
          <label style={{ color: "white", width: "auto" }}>
            <strong>Número de Estacionamiento: </strong>
            <span>{numeroDeEStacionamiento}</span>
          </label>
          {eventos.map((result) => {
            if (result.tipo_Registro === "Entrada") {
              return (
                <p
                  style={{
                    fontSize: "26px",
                    color: "white",
                    backgroundColor: "#D1e7DD",
                    color: "#0A3622",
                  }}
                >
                  {result.hora +
                    " " +
                    result.tipo_Registro +
                    " " +
                    result.ubicacion}
                </p>
              );
            } else if (result.tipo_Registro === "Salida"){
              return (
                <p
                  style={{
                    fontSize: "26px",
                    color: "white",
                    backgroundColor: "#F8D7DA",
                    color: "#58151C",
                  }}
                >
                  {result.hora +
                    " " +
                    result.tipo_Registro +
                    " " +
                    result.ubicacion}
                </p>
              );
            }else{
                return (
                    <p
                      style={{
                        fontSize: "26px",
                        color: "white",
                        backgroundColor: "#FFEB3B",
                        color: "#000000",
                      }}
                    >
                      {result.hora +
                        " " +
                        result.tipo_Registro +
                        " " +
                        result.ubicacion}
                    </p>
                  );
            }
          })}
          <table style={{ marginTop: "20px" }}>
            <thead>
              <tr>
                <td>Marca</td> <td>Modelo</td>
                <td>Color</td> <td>Placa</td>
              </tr>
            </thead>
            <tbody style={{ background: "white" }}>
              <tr>
                <td>{marca}</td>
                <td>{modelo}</td>
                <td>{color}</td>
                <td>{placa}</td>
              </tr>
              <tr>
                <td>{marca2}</td>
                <td>{modelo2}</td>
                <td>{color2}</td>
                <td>{placa2}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <button
              className='botonConsultarTicket' onClick={()=>{limpiarFormulario();setScanning(true)}}>Nueva consulta
            </button>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default QRReader;
