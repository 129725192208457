import React from "react";
import imagen from "../assets/LOGO COMPLETO CCG NEGRO.png";
import { useState, useEffect } from "react";
const url = "https://parkingapi.ccg.hn:4000";
const fecha = new Date();

function ConsultaF({ ...props }) {
  document.oncontextmenu = function () {
    return false;
  };
  const [correlativo, setCorrelativo] = useState("000-003-01-000");
  const [mostrarContenedorP, setMostrarContenedorP] = useState("none");

  const [mostrarPrincipal, setPrincipal] = useState("");

  const [mostrarBotones, setMostrarBotones] = useState("");

  const [mostrarEditar, setEditar] = useState("none");

  const [limite1, setlimite1] = useState("20/05/2023");
  const [limite2, setlimite2] = useState("29/06/2023");
  const [limite3, setlimite3] = useState("16/08/2023");
  const [limiteA, setlimiteA] = useState("");

  const [rango1, setrango1] = useState(
    "000-003-01-00000001-000-003-01-00050000"
  );
  const [rango2, setrango2] = useState(
    "000-003-01-00050001-000-003-01-00100000"
  );

  const [rango3, setrango3] = useState(
    "000-003-01-00100001-000-003-01-00150000"
  );
  const [rangoA, setrangoA] = useState("");

  const [cai1, setcai1] = useState("9E41AF-EE62C8-E34D9D-2F6D59-5CF268-E8");
  const [cai2, setcai2] = useState("11F6C7-8E7304-5A4CB3-9482AB-77651B-A5");
  const [cai3, setcai3] = useState("4C5864-0B43DC-5D48A2-9E6858-1D494E-69");
  const [caiA, setcaiA] = useState("");
  const [tipoC, setTipoC] = useState(`"${props.tipoC}"`);

  const [select1, setselect1] = useState("");
  const [select2, setselect2] = useState("");

  const [mostrarHora, setmostrarHora] = useState("");

  const [rtn, setrtn] = useState("");
  const [n, setn] = useState("");

  const recargar = () => {
    props.setmostrarB("");
    props.setmostrarF("none");
    props.setId_Factura("");
    props.setservicioMensual("none");
    setMostrarBotones("");
    setEditar("none");
    setPrincipal("");
    props.setNula("none");
  };

  const cancelar = () => {
    setMostrarBotones("");
    setEditar("none");
    setPrincipal("");
  };

  const enviarDatos = async () => {
    const NFactura = props.id_Factura2;
    const id_Ticket = props.id_Ticket;
    const nombre_Servicio = props.nombre_Servicio;

    if (tipoC === "Otro" && (rtn === "" || n === "")) {
      alert("Todos los campos son Obligatorios");
    } else if (tipoC === "") {
      alert("Todos los campos son Obligatorios");
    } else {
      const res = await fetch(`${url}/editarRTN`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          NFactura,
          id_Ticket,
          nombre_Servicio,
          tipoC,
          rtn,
          n,
        }),
      });

      const data = await res.json();

      if (data === "No se Actualizó Factura") {
        alert("No se Actualizó Factura");
      } else if (data === "No se Consultó RTN") {
        alert("No se Pudo Consultar RTN");
      } else if (data === "Se Actualizó Correctamente") {
        alert("Se Actualizó Correctamente");
        props.setmostrarB("");
        props.setmostrarF("none");
        props.setId_Factura("");
        props.setservicioMensual("none");
        setMostrarBotones("");
        setEditar("none");
        setPrincipal("");
        props.setNula("none");
        setn("");
        setrtn("");
      }
    }
  };

  const anularFactura = async () => {
    const id_Factura = props.id_Factura2;
    const res = await fetch(`${url}/anularFactura`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_Factura,
      }),
    });

    const data = await res.json();
    if (data === "Se Actualizó Estado de Factura") {
      alert("Se Actualizó Estado de Factura a Nula");
      props.setmostrarB("");
      props.setmostrarF("none");
      props.setId_Factura("");
      props.setservicioMensual("none");
      setMostrarBotones("");
      setEditar("none");
      setPrincipal("");
      props.setNula("none");
    } else if (data === "No se Actualizó Estado de Factura") {
      alert("No se Pudo Actualizar Estado de Factura");
    } else if (data === "No se encontró estado de Factura") {
      alert("No se encontró estado de Factura");
    }
  };

  useEffect(() => {
    if (parseInt(props.id_Factura2) <= parseInt(50000)) {
      setlimiteA(limite1);
      setrangoA(rango1);
      setcaiA(cai1);
      setCorrelativo("000-003-01-000");
    } else if (parseInt(props.id_Factura2) <= parseInt(100000)) {
      setlimiteA(limite2);
      setrangoA(rango2);
      setcaiA(cai2);
      setCorrelativo("000-003-01-000");
    } else if (parseInt(props.id_Factura2) <= parseInt(150000)) {
      setlimiteA(limite3);
      setrangoA(rango3);
      setcaiA(cai3);
      setCorrelativo("000-003-01-00");
    } else if (parseInt(props.id_Factura2) <= parseInt(200000)) {
      setlimiteA("26/09/2023");
      setrangoA("000-003-01-00150001-000-003-01-00200000");
      setcaiA("3844A4-C10FC0-5E4EA6-B2D4EA-17C05B-2A");
      setCorrelativo("000-003-01-00");
    } else if (parseInt(props.id_Factura2) <= parseInt(250000)) {
      setlimiteA("03/11/2023");
      setrangoA("000-003-01-00200001-000-003-01-00250000");
      setcaiA("2E0E6D-99174D-1C4F9C-300318-3BB27F-36");
      setCorrelativo("000-003-01-00");
    } else if (parseInt(props.id_Factura2) <= parseInt(300000)) {
      setlimiteA("23/12/2023");
      setrangoA("000-003-01-00250001-000-003-01-00300000");
      setcaiA("625D5F-C6BA4E-C74BB4-05BD43-1BA35A-CC");
      setCorrelativo("000-003-01-00");
    } else if (parseInt(props.id_Factura2) <= parseInt(400000)) {
      setlimiteA("09/02/2024");
      setrangoA("000-003-01-00300001-000-003-01-00400000");
      setcaiA("610BFA-B6AD29-AD4AAC-6BE1FC-EFAFAF-D5");
      setCorrelativo("000-003-01-00");
    } else if (parseInt(props.id_Factura2) <= parseInt(500000)) {
      setlimiteA("15/05/2024");
      setrangoA("000-003-01-00400001-000-003-01-00500000");
      setcaiA("0FD805-7AB1E3-D448A5-E994E6-488564-AA");
      setCorrelativo("000-003-01-00");
    } else if (parseInt(props.id_Factura2) <= parseInt(600000)) {
      setlimiteA("18/08/2024");
      setrangoA("000-003-01-00500001-000-003-01-00600000");
      setcaiA("44C001-E426B1-B34C91-8F18CB-ECC3C1-2B");
      setCorrelativo("000-003-01-00");
    } else if (parseInt(props.id_Factura2) <= parseInt(700000)) {
      setlimiteA("25/11/2024");
      setrangoA("000-003-01-00600001-000-003-01-00700000");
      setcaiA("68B492-7CA657-934AA4-1A0A91-C18EBB-5A");
      setCorrelativo("000-003-01-00");
    } else if (parseInt(props.id_Factura2) <= parseInt(710000)) {
      setlimiteA("04/03/2025");
      setrangoA("000-003-01-00700001-000-003-01-00710000");
      setcaiA("D3698B-CABF1D-234F9A-D6A9D2-4694D1-9E");
      setCorrelativo("000-003-01-00");
    } else if (parseInt(props.id_Factura2) <= parseInt(810000)) {
      setlimiteA("11/03/2025");
      setrangoA("000-003-01-00710001-000-003-01-00810000");
      setcaiA("8B6B74-3FC25C-234789-D114B6-852CEB-5A");
      setCorrelativo("000-003-01-00");
    } else if(parseInt(props.id_Factura2) <= parseInt(910000)) {
      setlimiteA("15/07/2025");
      setrangoA("000-003-01-00810001 / 000-003-01-00910000");
      setcaiA("1D521A-AFF6C3-494AE0-63BE03-090965-14");
      setCorrelativo("000-003-01-00");
    }else{
      setlimiteA("11/06/2025");
      setrangoA("000-003-01-00910001 / 000-003-01-01010000");
      setcaiA("21E258-281E55-9F41E0-63BE03-0909AB-8C");
      if(props.nFactura>999999){
        setCorrelativo("000-003-01-0");   
      }else{
        setCorrelativo("000-003-01-00"); 
      }
    }

    if (props.tipo_Cliente === "Consumidor Final") {
      setselect1("selected");
      setselect2("");
      setTipoC("Consumidor Final");
    } else {
      setselect1("");
      setselect2("selected");
      setTipoC("Otro");
    }

    setn(props.nombre);
    setrtn(props.RTN);

    if (props.servicioMensual === "") {
      setmostrarHora("none");
    } else {
      setmostrarHora("");
    }
  }, [props.id_Factura2]);

  return (
    <React.Fragment>
      <div
        className='ContenedorMostrarF'
        style={{ display: `${mostrarPrincipal}` }}
      >
        <div style={{ marginTop: "2rem", display: `${mostrarBotones}` }}>
          <button
            className='btn btn-warning3'
            onClick={() => {
              setEditar("");
              setPrincipal("none");
            }}
            style={{ marginRight: "1rem" }}
          >
            Editar Factura
          </button>

          <button
            className='btn btn-warning2'
            onClick={() => {
              setMostrarBotones("none");
              setTimeout(() => {
                window.print();
              }, 500);
            }}
            style={{ marginRight: "1rem" }}
          >
            Imprimir Factura
          </button>

          <button className='btn btn-warning4' onClick={anularFactura}>
            Anular Factura
          </button>
        </div>
        <section>
          <h1>Centro Civíco</h1>
          <h1>Gubernamental</h1>
          <img src={imagen} className='imagen' alt='Logo' onClick={recargar} />
        </section>

        <section className='contenedorDatosFactura'>
          <h2 style={{ marginTop: "1.5rem" }}>
            <b>
              {props.Fecha.slice(8, 10) +
                "/" +
                props.Fecha.slice(5, 7) +
                "/" +
                props.Fecha.slice(0, 4)}
            </b>
          </h2>

          <label className='labelDatosFactura'>
            <b>Concepto: Pago de Estacionamiento</b>
          </label>
          <label className='labelDatosFactura'>Auto Impresión</label>
          <label className='labelDatosFactura'>CAI:</label>
          <label className='labelDatosFacturac'>{caiA}</label>
          <label className='labelDatosFactura'>DOIH, SA DE CV</label>
          <label className='labelDatosFactura'>RTN: 08019016827575</label>
          <label className='labelDatosFactura'>Rango de Impresión:</label>
          <label className='labelDatosFacturac'>{rangoA}</label>
          <label className='labelDatosFactura'>
            Límite de Emisión: {limiteA}
          </label>
          <label className='labelDatosFacturac'>
            Factura Original N°:{correlativo + props.id_Factura2}
          </label>
        </section>

        <section className='contenedorInfoFactura'>
          <label style={{ fontSize: "2rem", width: "auto" }}>
            Cliente: {props.tipo_Cliente}
          </label>
          <label style={{ fontSize: "1.8rem", display: `${props.ocultar}` }}>
            RTN:{" "}
            <span style={{ fontSize: "1.6rem", width: "auto" }}>
              {props.RTN}
            </span>
          </label>

          <label
            style={{
              fontSize: "1.8rem",
              width: "auto",
              display: `${props.ocultar}`,
            }}
          >
            Nombre: {props.nombre}
          </label>

          <label style={{ fontSize: "2rem", marginTop: "1rem", width: "auto" }}>
            Servicio: {props.nombre_Servicio}
          </label>

          <label style={{ fontSize: "2rem", display: `${mostrarHora}` }}>
            Hora Entrada: {props.hora_Entrada}
          </label>
          <label style={{ fontSize: "2rem", display: `${mostrarHora}` }}>
            <b>Hora Salida: {props.hora_Salida}</b>
          </label>

          <label
            style={{
              fontSize: "2rem",
              width: "auto",
              display: `${props.servicioMensual}`,
            }}
          >
            Tipo Estacionamiento: Temporal
            <span
              style={{ fontSize: "2rem", display: `${props.servicioMensual}` }}
            ></span>
          </label>
          <label
            style={{
              fontSize: "2rem",
              width: "auto",
              display: `${props.servicioMensual}`,
            }}
          >
            Tipo Pago: {props.values[2]}
            <span style={{ fontSize: "2rem" }}></span>
          </label>

          <label
            style={{
              fontSize: "2rem",
              width: "auto",
              display: `${mostrarContenedorP}`,
            }}
          >
            Cantidad de Días: {props.values[14]}
            <span style={{ fontSize: "2rem" }}></span>
          </label>

          <label
            style={{
              fontSize: "2rem",
              width: "auto",
              display: `${props.servicioMensual}`,
            }}
          >
            Tipo Vehículo: {props.values[1]}
            <span style={{ fontSize: "2rem" }}></span>
          </label>
          <label
            style={{
              fontSize: "2rem",
              width: "auto",
              display: `${props.servicioMensual}`,
            }}
          >
            Nombre: {props.values[3]}
            <span style={{ fontSize: "2rem", width: "auto" }}></span>
          </label>
          <label
            style={{
              fontSize: "2rem",
              width: "auto",
              display: `${props.servicioMensual}`,
            }}
          >
            Marca: {props.values[4]}
            <span style={{ fontSize: "2rem" }}></span>
          </label>
          <label
            style={{
              fontSize: "2rem",
              width: "auto",
              display: `${props.servicioMensual}`,
            }}
          >
            Modelo: {props.values[5]}
            <span style={{ fontSize: "2rem" }}></span>
          </label>
          <label
            style={{
              fontSize: "2rem",
              width: "auto",
              display: `${props.servicioMensual}`,
            }}
          >
            Placa: {props.values[6]}
            <span style={{ fontSize: "2rem" }}></span>
          </label>
          <label
            style={{
              fontSize: "2rem",
              width: "auto",
              display: `${props.servicioMensual}`,
            }}
          >
            Teléfono: {props.values[7]}
            <span style={{ fontSize: "2rem" }}></span>
          </label>

          <label style={{ fontSize: "2rem" }}>
            Subtotal: LPS.{parseFloat(props.subtotal).toFixed(2)}
          </label>
          <label style={{ fontSize: "2rem" }}>
            ISV: LPS.{parseFloat(props.ISV).toFixed(2)}
          </label>
          <label style={{ fontSize: "2rem" }}>
            Total: LPS.{parseFloat(props.total).toFixed(2)}
          </label>
          <label style={{ fontSize: "2rem" }}>
            Recibido: LPS.{parseFloat(props.recibido).toFixed(2)}
          </label>
          <label style={{ fontSize: "2rem" }}>
            Cambio: LPS.{parseFloat(props.cambio).toFixed(2)}{" "}
          </label>
        </section>
        <b>
          <p
            style={{
              width: "35rem",
              fontSize: "1.2rem",
              textAlign: "start",
              fontFamily: "PT Serif, serif",
            }}
          >
            Dirección: Blvd. Juan Pablo Segundo esquina con República de Corea
            Tegucigalpa, M.D.C. Honduras C.A. Teléfono: +5042243-7241. Correo
            electrónico: Ives.jimenez@doih.hn
          </p>{" "}
        </b>
      </div>

      <div
        style={{ display: "flex", width: "100vw", justifyContent: "center" }}
      >
        <div style={{ display: `${mostrarEditar}` }}>
          <label>Tipo de Cliente</label>
          <select
            style={{ display: "block", fontSize: "2rem", width: "255.2px" }}
            onChange={(e) => {
              setTipoC(e.target.value);
            }}
          >
            <option selected={select1} value='Consumidor Final'>
              Consumidor Final
            </option>
            <option selected={select2} value='Otro'>
              Otro
            </option>
          </select>

          <label>RTN</label>
          <input
            type='text'
            value={rtn}
            style={{ display: "block", fontSize: "2rem" }}
            onChange={(e) => {
              setrtn(e.target.value);
            }}
          />

          <label>Nombre</label>
          <input
            type='text'
            value={n}
            style={{ display: "block", fontSize: "2rem" }}
            onChange={(e) => {
              setn(e.target.value);
            }}
          />

          <button
            className='btn btn-warning2'
            style={{ marginTop: "2rem", marginRight: "1rem" }}
            onClick={enviarDatos}
          >
            Guardar Cambio
          </button>
          <button
            className='btn btn-warning4'
            style={{ marginTop: "2rem" }}
            onClick={cancelar}
          >
            Cancelar Cambio
          </button>
        </div>

        <div className='marca' style={{ display: `${props.nula}` }}>
          <p>Factura Nula</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ConsultaF;
